import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import OffBoadingEmployeeInfo from '../OffBoadingEmployeeInfo/OffBoadingEmployeeInfo';
import OffboadingEmployeeDetails from '../../api/FetchOffboadingEmployeeDetails';
import OffboadingSaveEmployeeDetails from '../../api/OffboadingSaveEmployeeDetails';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OffBoadingAccount = ({department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [formData, setFormData] = useState({
        no_dues: false,
        no_dues_comment: '',
        comment: '',
        remove_from_salary_list: false,
        //all_dues_clear_comment: ''
    });
    const [formErrors, setFormErrors] = useState({
        no_dues_comment: '',
        comment: '',
        all_dues_clear_comment: ''
    });
    

    useEffect(() => {
        const url = 'get-account';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const employeeData = await OffboadingEmployeeDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.data;
                        setFormData({
                            no_dues: empDetails.no_dues || false,
                            no_dues_comment: empDetails.no_dues_comment || '',
                            remove_from_salary_list: empDetails.remove_from_salary_list || false,
                            //all_dues_clear_comment: empDetails.all_dues_clear_comment || '',
                            comment:empDetails.comment || ''
                            
                        });

                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }
                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId]);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked,
            // Reset related comment fields if unchecked
            it_comment: name === 'it' && !checked ? '' : prevState.it_comment,
            admin_comment: name === 'admin' && !checked ? '' : prevState.admin_comment
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const validateForm = () => {
        let valid = true;
        const errors = {
            no_dues_comment: '',
            comment: '',
            all_dues_clear_comment: ''
        };

        if (formData.no_dues && !formData.no_dues_comment) {
            toast.error('No Dues comment is required');
            valid = false;
        }

        if (!formData.comment) {
           toast.error('Comment is required');
            valid = false;
        }

        // Validate it_comment if it is mandatory
        if (formData.all_dues_clear && !formData.all_dues_clear_comment) {
            toast.error('All Dues comment is required');
            valid = false;
        }

        
        setFormErrors(errors);
        return valid;
    };

    const handleSaveInfo = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        setLoading(true);
       

        try {
            setLoading(true);
            const url = 'create-account';
            setLoading(true);
            const requestData = {
                ...formData,
                status: "Pending",
                created_by: admin,
            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            console.log('Form data saved:', response.data);
            toast.success('Form data saved successfully!');
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        setLoading(true);
        

        try {
            setLoading(true);
            const url = 'create-account';
            setLoading(true);
            const requestData = {
                ...formData,
                status: "Submitted",
                created_by: admin,
            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success('Form data Submitted successfully!');
            //onSubmit(activeTabIndex);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setButtonsDisabled(true);
            setLoading(false);
        }
    };

    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}></div>

                <OffBoadingEmployeeInfo token={token} employeeId={employeeId} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={`${styles.fieldset} ${buttonsDisabled ? styles['fieldset-disabled'] : ''}`}>
                            <legend>Account</legend>
                            <div className={styles.grid} style={{ marginTop: "10px" }}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>No Dues:</strong>
                                                <span>
                                                    <input type="checkbox" id="no_dues" name="no_dues" checked={formData.no_dues}
                                                        onChange={handleCheckboxChange} disabled={buttonsDisabled}/>
                                                    <label htmlFor="no_dues"></label>
                                                    {formData.no_dues && (
                                                        <textarea name="no_dues_comment" className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`} value={formData.no_dues_comment} placeholder="No Dues Comment" onChange={handleChange} disabled={buttonsDisabled}></textarea>
                                                    )}
                                                </span>
                                                {formErrors.no_dues_comment && <div className={styles['empl-error']}>{formErrors.no_dues_comment}</div>}
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={`${styles['two-col']}  `}>
                                        <li>
                                                <strong>Remove From Salary List:</strong>
                                                <span>
                                                    <input type="checkbox" id="remove_from_salary_list" name="remove_from_salary_list" checked={formData.remove_from_salary_list}
                                                        onChange={handleCheckboxChange} disabled={buttonsDisabled}/>
                                                    <label htmlFor="remove_from_salary_list"></label>
                                                    {formData.all_dues_clear && (
                                                        <textarea name="all_dues_clear_comment" className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`} value={formData.all_dues_clear_comment} placeholder="Comment" onChange={handleChange} disabled={buttonsDisabled}></textarea>
                                                    )}
                                                </span>
                                                {formErrors.all_dues_clear_comment && <div className={styles['empl-error']}>{formErrors.all_dues_clear_comment}</div>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles['right-space']}>
                                    <ul className={`${styles['two-col']}  `}>
                                        <li>
                                            <strong>Comment<i className={styles['red']}>*</i>:</strong>
                                            <span>
                                                <textarea name="comment" className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`} value={formData.comment} onChange={handleChange} disabled={buttonsDisabled}></textarea>
                                            </span>
                                            {formErrors.comment && <div className={styles['empl-error']}>{formErrors.comment}</div>}
                                        </li>
                                    </ul>
                                </div>
                               
                            </div>
                        </fieldset>
                        {/* Button block */}
                        <div className={styles['button-block']}>
                        {!buttonsDisabled && department !== "Human Resources" && (
                                        <input type="submit" value="Save" className={styles.button} onClick={handleSaveInfo} disabled={loading} />
                                    )}

                                  {!buttonsDisabled && department !== "Human Resources" && (
                                        <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={handleSubmit} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    )}
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OffBoadingAccount;
