import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext'
import Header from '../Header/Header';
import Welcome from './Welcome';
import Application from './Application';
import LeftPopup from '../Details/LeftPopup';
import RightPopup from '../Details/RightPopup';
import Footer from '../Footer/Footer';
import styles from './Main.module.scss';
import StepEmitter from '../../../components/StepEmitter/StepEmitter'
import GetCandidateDetails from '../../../api/fetchCandidateData'

const MainContent = () => {
  const [step, setStep] = useState(1);
  const [popupVisible, setPopupVisible] = useState(false);
  const [displayStyle, setDisplayStyle] = useState('none');
  const [submitStep, setsubmitStep] = useState('none');
  const [isEdit, setIsEdit] = useState(false);
  const [previewStep, setPreviewStep] = useState(3);
  const { token, zoho_candidate_id } = useToken();
  const [basicDetails, setBasicDetails] = useState('');
  const [designationDetails, setDesignationDetails] = useState('');
  const [apiData, setApiData] = useState(null);
  const [apiDataStatus, setApiDataStatus] = useState(null);
  const [error, setError] = useState('');
  const [stepNumber, setStepNumber] = useState(1);
  const url = new URL(window.location.href);  
const tokens = url.searchParams.get('token'); 
console.log(tokens)
   const handleClosePopup = () => {
    setPopupVisible(false);
    document.body.classList.remove(styles['fixed-overlay']);
    document.body.classList.remove(styles['fixed-overlay-preview']);
  };
  useEffect(() => {
    const handleStepChange = newStep => {
      setStep(newStep);
    };

    StepEmitter.on('stepChange', handleStepChange);

    // Clean up event listener on component unmount
    return () => {
      StepEmitter.off('stepChange', handleStepChange);
    };
  }, []);

  useEffect(() => {
    const handleStepChange = newStep => {
      setStep(newStep);
    };

    StepEmitter.on('stepChange', handleStepChange);

    // Clean up event listener on component unmount
    return () => {
      StepEmitter.off('stepChange', handleStepChange);
    };
  }, []);

  const fetchCandidateDetails = async () => {
    try {
      const view = false;
      const details = await GetCandidateDetails(token, zoho_candidate_id,view);
      const fullName = details.data.basic_details.First_Name + ' ' + details.data.basic_details.Last_Name;
      setBasicDetails(fullName);
      setDesignationDetails(details.Designation);
      setApiData(details.data);
      setApiDataStatus(details.status);
      setStepNumber(details.data.Process_Status);
      if (details.status !== 200) {
        setError(details.msg);
      } else {
        setError(details.msg); 
      }
    } catch (error) {
      
      if (error && tokens) {
        setError('You have already submitted the employment form!'); 
      } else {
        setError('Token or candidate id might be wrong'); 
      }
    }
  };
  


  useEffect(() => {
    fetchCandidateDetails();
  }, []);
  const updateApiData = (newData) => {
    setApiData(newData);
};

  const handlePreviewClick = (val) => {
    if(val === 'Yes'){
      setsubmitStep('Yes');
    }
    else{
      setsubmitStep('');
    }
    if (!isEdit) {
      setIsEdit(true);
    }
    handleClosePopup();
    
    //setStep(3);
    //document.body.classList.remove(styles['pop-overlay']);
    setDisplayStyle('inline-block');
    document.body.classList.remove(styles['fixed-overlay']);
    document.body.classList.remove(styles['fixed-overlay-preview']);
    //setPreviewStep(step);
  };
 
  // Function to update the step value
  const handleStepChange = (newSteps) => {
    setStep(newSteps);
  };

  return (
    <section>
      <Header basicDetails={basicDetails}/>
      <Welcome basicDetails={basicDetails} designationDetails={designationDetails} stepNumber={stepNumber}/>
      <Application
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
        displayStyle={displayStyle}
        setStep={handleStepChange}
        apiDataStatus={apiDataStatus} 
        error={error} 
        isEdit={isEdit} setIsEdit={setIsEdit}
        stepNumber={stepNumber}
        submitStep={submitStep}
      />
      <div className={styles['pop-overlay']}></div>
      <div className={`${styles.popup} ${popupVisible ? styles.show : ''}`}>
        <div className={styles['popup-content']}>
        {step !== 11 && (
            <i className={styles['close-popup']}
              id="close-popup"
              onClick={handleClosePopup}>
            </i>
            )}
          <LeftPopup step={step} setStep={setStep}/>
          <RightPopup   isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} step={step} setStep={setStep} setPopupVisible={setPopupVisible} displayStyle={displayStyle} apiData={apiData} setDisplayStyle={setDisplayStyle} setApiData={setApiData}/>
          <div className={styles.clear}></div>
        </div>
      </div>
      <div className={styles['pop-overlay-preview']}></div>
      {/*<Preview />*/}
      <Footer />
    </section>

  );
};

export default MainContent;

