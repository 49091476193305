import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext'
import InputField from '../../../components/InputField/InputField'
import Button from '../../../components/Button/Button'
import styles from '../MainContent/Main.module.scss'
import Rreview from '../Details/Preview'
import SelectField from '../../../components/SelectField/SelectField'
import DatePicker from "react-datepicker";
import ProgressBar from './ProgressBar'
import GetCandidateDetails from '../../../api/fetchCandidateData'
import SaveCandidateData from '../../../api/SaveCandidateData'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'
import SaveCandidateResume from '../../../api/SaveResume'

const Language = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData, updateApiData }) => {
    const { token, zoho_candidate_id } = useToken();
    const [showRreview, setshowRreview] = useState(false);
    const [date, setDate] = useState(new Date());
    const [languages, setLanguages] = useState([{ id: 1, Language: '', Language_Write: '', Language_Read: '', Language_Speak: '' }]);
    const [events, setEvents] = useState([{ id: 1, Event_Name: '', Event_Date: '', Represented_Whom: '', Awards_Received: '' }]);
    const totalSteps = 12
    const nameRegex = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    const [isChecked, setIsChecked] = useState(true);
    const [resumeLink, setresumeLink] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const [imageSizeMessage, setimageSizeMessage] = useState('');
    const [fileUploaded, setFileUploaded] = useState(false);
    const [formData, setFormData] = useState({
        Hobby: "",
        Other_Information: "",
        Current_Salary: "",
        Expected_Salary: "",
        Notice_preiod: "",
        When_Join: "",
        Declare_Status: "",
        Candidate_Resume: "",
        errors: {}
    });

    // Function to handle adding a new language field
    const handleAddLanguage = () => {
        const newLanguage = {
            id: languages.length + 1,
            Language: '',
            Language_Knowledge: ''
        };
        setLanguages([...languages, newLanguage]);
    };

    // Function to handle removing a language field
    const handleRemoveLanguage = (index) => {
        const updatedLanguages = [...languages]; // Create a copy of the languages array
        updatedLanguages.splice(index, 1); // Remove the language at the specified index
        setLanguages(updatedLanguages);
    };

    const validateForm = () => {
        let isValid = true;
        const newLanguages = [...languages];


        // Validate language fields
        newLanguages.forEach((language, index) => {
            if (
                !language.Language.trim() ||
                !nameRegex.test(language.Language) ||
                !language.Language_Knowledge
            ) {
                isValid = false;
                newLanguages[index].error = true;

            } else {
                newLanguages[index].error = false;
            }
        });

        //event validation


        // Validate form data fields
        const formDataErrors = {};

        if (!formData.Current_Salary) {
            formDataErrors.Current_Salary = 'This field is required';
            isValid = false;

        }
        else if (formData.Current_Salary < 0) {
            formDataErrors.Current_Salary = 'Salary should be be more than 0';
            isValid = false;

        }

        if (!formData.Expected_Salary) {
            formDataErrors.Expected_Salary = 'This field is required';
            isValid = false;

        }
        else if (formData.Expected_Salary < 0) {
            formDataErrors.Expected_Salary = 'Salary should be be more than 0';
            isValid = false;

        }

        if (!formData.Notice_preiod.trim()) {
            isValid = false;
            formDataErrors.Notice_preiod = 'This field is required';
        }

        if (!formData.When_Join.trim()) {
            isValid = false;
            formDataErrors.When_Join = 'This field is required';
        }

        if (!isChecked) {
            isValid = false;
        }
        setLanguages(newLanguages); // Update languages state
        setFormData(prevState => ({
            ...prevState,
            errors: formDataErrors // Update errors within formData state
        }));

        return isValid;
    };



    // Function to handle adding a new event field
    const handleAddEvent = () => {
        const newEvent = {
            id: events.length + 1,
            Event_Name: '',
            Event_Date: '',
            Represented_Whom: '',
            Awards_Received: ''
        };
        setEvents([...events, newEvent]);
    };

    // Function to handle removing a event field
    const handleRemoveEvent = (index) => {
        const updatedEvents = [...events]; // Create a copy of the languages array
        updatedEvents.splice(index, 1); // Remove the language at the specified index
        setEvents(updatedEvents);
    };

    const handleDateChange = (date, index, field) => {
        const newEvents = [...events];
        newEvents[index][field] = date;
        setEvents(newEvents);
    };


    const handleLanguageInputChange = (index, field, value) => {
        const updatedLanguages = [...languages];
        updatedLanguages[index] = { ...updatedLanguages[index], [field]: value };
        setLanguages(updatedLanguages);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const notice = [
        { value: '-1', label: 'Select Notice' },
        { value: 'None', label: 'None' },
        { value: 'Within 15 days', label: 'Within 15 days' },
        { value: 'Within 1 month', label: 'Within 1 month' },
        { value: 'Within 2 month', label: 'Within 2 month' },
        { value: 'Within 3 month', label: 'Within 3 month' },

    ];

    const Join = [
        { value: '-1', label: 'Select Option' },
        { value: 'Immediately', label: 'Immediately' },
        { value: 'Within 15 days', label: 'Within 15 days' },
        { value: 'Within 1 month', label: 'Within 1 month' },
        { value: 'Within 2 month', label: 'Within 2 month' },
        { value: 'Within 3 month', label: 'Within 3 month' },
    ];
    const handleBackClick = () => {
        StepEmitter.emit('stepChange', step - 1);
    }
    const handleInputChange = (index, field, value) => {
        const newEvents = [...events];
        newEvents[index][field] = value;
        setEvents(newEvents);
    };

    const handleSalaryChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };
    // Function to fetch candidate details from API
    const fetchCandidateDetails = async () => {
        try {
            const details = await GetCandidateDetails(token, zoho_candidate_id);
            const apiData = details.data;
            if (apiData.candidate_language_known_skills && apiData.candidate_language_known_skills.length > 0) {
                // Update languages state with fetched data
                setLanguages(apiData.candidate_language_known_skills.map(language => ({
                    id: language.id,
                    Language: language.Language,
                    Language_Knowledge: language.Language_Knowledge
                })));
            } else {
                // If no language data is returned, initialize with default values
                setLanguages([{ id: 1, Language: '', Language_Knowledge: '' }]);
            }
            if (apiData.candidate_scholarship_award && apiData.candidate_scholarship_award.length > 0) {
                setEvents(apiData.candidate_scholarship_award);

            }
            if (apiData.candidate_other_information) {

                setFormData({
                    ...formData,
                    Hobby: apiData.candidate_other_information.Hobby || '',
                    Other_Information: apiData.candidate_other_information.Other_Information || '',
                    Current_Salary: apiData.candidate_other_information.Current_Salary || '',
                    Expected_Salary: apiData.candidate_other_information.Expected_Salary || '',
                    Notice_preiod: apiData.candidate_other_information.Notice_preiod || '',
                    When_Join: apiData.candidate_other_information.When_Join || '',
                    Declare_Status: apiData.candidate_other_information.Declare_Status || '',
                    Candidate_Resume: apiData.candidate_other_information.Candidate_Resume || ''

                });

            }
        } catch (error) {
            console.error('Error fetching candidate details:', error);

        }
    };


    useEffect(() => {
        fetchCandidateDetails();
    }, []);

    const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const handleContinueClick = async () => {
        try {

            const isValid = validateForm();

            if (!isValid) {
                
                return; 

            }
            const awardsToSave = events.map(event => {
                let formattedEvent_Date = event.Event_Date;

                if (formattedEvent_Date) {

                    if (typeof formattedEvent_Date === 'string' && formattedEvent_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {

                    } else {
                        formattedEvent_Date = formatDate(event.Event_Date);
                    }
                } else {
                   
                }

                return {
                    Event_Name: event.Event_Name,
                    Event_Date: formattedEvent_Date,
                    Event_Year: 0,
                    Represented_Whom: event.Represented_Whom,
                    Awards_Received: event.Awards_Received,
                };
            });

            const languageToSave = languages.map(language => ({
                Language: language.Language,
                Language_Knowledge: language.Language_Knowledge

            }));

            const dataToSave = {
                candidate_language_known_skills: languageToSave,
                candidate_scholarship_award: awardsToSave,
                candidate_other_information: {
                    ...formData,
                    Declare_Status: isChecked,
                },
                Zoho_Item_Id: zoho_candidate_id,
                Process_Status: "10"
            };
            const response = await SaveCandidateData(dataToSave);
            if (response.status === 200) {
                setshowRreview(true);
                StepEmitter.emit('stepChange', step + 1);
            } else {
                console.error('Error saving candidate data:', response.statusText);

            }
        } catch (error) {
            console.error('Error saving candidate data:', error);

        }


    };

    const handlePreviewPage = async () => {
        try {

            const isValid = validateForm();

            if (!isValid) {
                // Show error message to the user
                
                return; // Prevent form submission

            }
            const awardsToSave = events.map(event => {
                let formattedEvent_Date = event.Event_Date;

                if (formattedEvent_Date) {

                    if (typeof formattedEvent_Date === 'string' && formattedEvent_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {

                    } else {
                        formattedEvent_Date = formatDate(event.Event_Date);
                    }
                } else {
                    console.log('date is empty');
                }

                return {
                    Event_Name: event.Event_Name,
                    Event_Date: formattedEvent_Date,
                    Event_Year: 0,
                    Represented_Whom: event.Represented_Whom,
                    Awards_Received: event.Awards_Received,
                };
            });

            const languageToSave = languages.map(language => ({
                Language: language.Language,
                Language_Knowledge: language.Language_Knowledge

            }));

            const dataToSave = {
                candidate_language_known_skills: languageToSave,
                candidate_scholarship_award: awardsToSave,
                candidate_other_information: {
                    ...formData,
                    Declare_Status: isChecked,
                },
                Zoho_Item_Id: zoho_candidate_id,
                Process_Status: "11"
            };
            const response = await SaveCandidateData(dataToSave);
            if (response.status === 200) {
                setshowRreview(true);

                setStep(11);
                StepEmitter.emit('stepChange', 11);

            } else {
                console.error('Error saving candidate data:', response.statusText);

            }
        } catch (error) {
            console.error('Error saving candidate data:', error);

        }


    };
    const [selectedFile, setSelectedFile] = useState(null);

    // Function to handle file selection
    const handleFileChange = (event) => {
        setUploadMessage('');
        setimageSizeMessage('');
        setSelectedFile(event.target.files[0]);
        handleFileUpload(event.target.files[0]);
    };

    // Function to handle file upload
    const handleFileUpload = async (file) => {
        if (file) {
            if (file.size > 900 * 1024) {
                setUploadMessage('');
                setimageSizeMessage('File size exceeds the limit (900kb)');
                return;
            }
            // Check file type
            const fileType = file.type;
            if (!(fileType === 'application/pdf' || fileType === 'application/msword')) {
                setUploadMessage('');
                setimageSizeMessage('Only PDF and DOC files are allowed');
                return;
            }
            setIsUploading(true);
            const formData = new FormData();
            formData.append('Resume', file);

            try {
                const response = await SaveCandidateResume(formData, zoho_candidate_id);
                if (response.status === 200) {
                    setimageSizeMessage('');
                    setUploadMessage('File uploaded successfully');
                    await fetchCandidateDetails();
                } else {
                    setimageSizeMessage('Failed to upload file');
                }
            } catch (error) {
                console.error('Error saving candidate image:', error);
                setimageSizeMessage('Error uploading file');
            }
            finally {
                setIsUploading(false);
            }
        } else {
            console.warn('No file selected');
        }
    };

    return (
        <>
            {step === 10 && (
                <>
                    <ProgressBar currentStep={step} totalSteps={totalSteps} />
                    <div className={styles['required-details']}>
                        Kindly fill the required details <span>*</span> of the form below.
                    </div>
                    <h4 className={styles.heading1} style={{ display: step === 10 ? 'block' : 'none' }}>
                        <span>Other Information</span>
                    </h4>
                    <div className={`${styles['row-c']} ${styles.width} ${styles['other-INFORMATION2']}`} style={{ display: step === 10 ? 'block' : 'none' }}>
                        <form id="other-INFORMATION2" name="other-INFORMATION2">
                            <div className={styles['other']}>
                                <div className={styles['other-info2']}>
                                    <p><strong>Languages known:</strong></p>
                                    <ul><li className={`${styles.morelanguage}`}><strong className={styles.strong}>Language<i className={styles.red}>*</i>:</strong></li></ul>
                                    {languages.map((language, index) => (
                                        <ul id="morelanguage" key={index}> {/* Add key prop to the parent element */}
                                            <li className={`${styles.morelanguage}`}>
                                                <InputField
                                                    type="text"
                                                    id={`Language_${index}`}
                                                    name={`Language_${index}`}
                                                    className={styles.input}
                                                    mainClass={styles['div-right']}
                                                    value={language.Language}
                                                    error={(language.error && !language.Language.trim()) || (language.error && !nameRegex.test(language.Language.trim())) ? "should only contain characters" : ''}
                                                    onChange={(e) => handleLanguageInputChange(index, 'Language', e.target.value)}
                                                />
                                            </li>
                                            <li>

                                                <div className={styles['div-right']}>
                                                    <ol className={styles['yes-no-option']}>
                                                        <li className={styles['language-options']} >
                                                            <input
                                                                type="radio"
                                                                name={`Write_Basic_${index}`}
                                                                id={`write_Basic_${index}`}
                                                                value="Basic"
                                                                required="required"
                                                                checked={language.Language_Knowledge === 'Basic'} // Adjusted checked condition
                                                                onChange={(e) => handleLanguageInputChange(index, 'Language_Knowledge', e.target.value)}
                                                            />
                                                            <label htmlFor={`write_Basic_${index}`} className={(language.error && !language.Language_Knowledge.trim()) ? styles.radioError : ''}>
                                                                <span>Basic</span>
                                                            </label>

                                                        </li>

                                                    </ol>
                                                </div>
                                            </li>
                                            <li>

                                                <div className={styles['div-right']}>
                                                    <ol className={styles['yes-no-option']}>
                                                        <li className={styles['language-options']}>
                                                            <input
                                                                type="radio"
                                                                name={`Write_Intermediate_${index}`} // Different name attribute for each group
                                                                id={`write_Intermediate_${index}`}
                                                                value="Intermediate"
                                                                required="required"
                                                                checked={language.Language_Knowledge === 'Intermediate'} // Adjusted checked condition
                                                                onChange={(e) => handleLanguageInputChange(index, 'Language_Knowledge', e.target.value)}
                                                            />
                                                            <label htmlFor={`write_Intermediate_${index}`} className={(language.error && !language.Language_Knowledge.trim()) ? styles.radioError : ''}>
                                                                <span>Intermediate</span>
                                                            </label>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                            <li>

                                                <div className={styles['div-right']}>
                                                    <ol className={styles['yes-no-option']}>
                                                        <li className={styles['language-options']}>
                                                            <input
                                                                type="radio"
                                                                name={`Write_Advance_${index}`}
                                                                id={`write_Advance_${index}`}
                                                                value="Advanced"
                                                                required="required"
                                                                checked={language.Language_Knowledge === 'Advanced'}
                                                                onChange={(e) => handleLanguageInputChange(index, 'Language_Knowledge', e.target.value)}
                                                            />
                                                            <label htmlFor={`write_Advance_${index}`} className={(language.error && !language.Language_Knowledge.trim()) ? styles.radioError : ''}>
                                                                <span>Advanced</span>
                                                            </label>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>


                                            {index === 0 && (
                                                <li>
                                                    <input id="addlanguage" className={`${styles.BTNadd2}`} type="button" value="Add" onClick={handleAddLanguage} />
                                                </li>
                                            )}
                                            {index >= 1 && (
                                                <li>
                                                    <input type="button" className={`${styles.remove} ${styles.remove2}`} value="Remove" onClick={() => handleRemoveLanguage(index)} />
                                                </li>
                                            )}

                                        </ul>
                                    ))}
                                </div>



                                <div className={styles['other-details']}>
                                    <div className={`${styles['row-c']} ${styles.other}`}>
                                        <div className={styles['col-left']}>
                                            <ul>
                                                <li> <span className={styles.span}>Hobbies/extra curriculam activities:</span>
                                                    <div className={styles.div}>
                                                        <textarea
                                                            id="Hobby"
                                                            name="Hobby"
                                                            className={`${styles.input} ${styles.textarea}`}
                                                            value={formData.Hobby}
                                                            onChange={(e) => handleSalaryChange(e.target.name, e.target.value)} // Pass name and value separately
                                                        ></textarea>
                                                    </div>
                                                </li>
                                                <li> <span className="span">Any other information you wish to provide in support of your
                                                    candidature:</span>
                                                    <div className={styles.div}>
                                                        <textarea
                                                            id="Other_Information"
                                                            name="Other_Information"
                                                            className={`${styles.input} ${styles.textarea}`}
                                                            value={formData.Other_Information}
                                                            onChange={(e) => handleSalaryChange(e.target.name, e.target.value)} // Pass name and value separately
                                                        ></textarea>
                                                    </div>
                                                </li>


                                                <li>
                                                    <span className="span">Upload  Resume:</span>
                                                    <div className={`${styles.div} ${styles.relative}`}>
                                                        <div className={styles['resume-container']}>
                                                            <div className={styles['button-wrap']}>
                                                                <span style={{ color: "#ccc", fontSize: "14px" }}>(pdf/doc only)</span>

                                                                <label className={styles.button} htmlFor="upload">Upload File</label>
                                                                <input id="upload" name="Resume" className="uploadResume" type="file" onChange={handleFileChange} />
                                                            </div>
                                                            {formData.Candidate_Resume && (
                                                                <div className={styles['dowunload-resume']}>
                                                                    <div className={styles['button-wrap-download']}>
                                                                        <a href={formData.Candidate_Resume} className={styles.resumeDownloadBtn}><i></i></a>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {isUploading && (
                                                                <div className="spinner">
                                                                    {/* Insert spinner content here */}
                                                                    Loading...
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li> <span className="span"></span>
                                                    <div className={styles.div}>
                                                        <div className={styles['resume-container']}>
                                                            <div className={styles['button-wrap']}>
                                                                <span id="uploadResume">{uploadMessage}</span>
                                                                <span className={styles.resuneError}>{imageSizeMessage}</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={styles.clear}></div>
                                </div>


                                <div className={`${styles['row-c']} ${styles.width}`}>
                                    <h5>Salary Expected</h5>
                                    <div className={styles.salary}>
                                        <ul>
                                            <li><strong className={styles.strong}>Renumeration Expected<i className={styles.red}>*</i>: <span className={styles['span-small']}></span></strong>
                                                <InputField
                                                    type="number"
                                                    id="Expected_Salary"
                                                    name="Expected_Salary"
                                                    className={styles.input}
                                                    mainClass={styles['div-right']}
                                                    error={(formData.errors.Expected_Salary) ? formData.errors.Expected_Salary : ''}
                                                    value={formData.Expected_Salary}
                                                    onChange={(e) => handleSalaryChange(e.target.name, e.target.value)}
                                                    min="0"
                                                />

                                            </li>

                                            <li><strong className={styles.strong}>Current SALARY<i className={styles.red}>*</i>: <span className={styles['span-small']}></span></strong>
                                                <InputField
                                                    type="number"
                                                    id="Current_Salary"
                                                    name="Current_Salary"
                                                    className={styles.input}
                                                    mainClass={styles['div-right']}
                                                    error={(formData.errors.Current_Salary) ? formData.errors.Current_Salary : ''}
                                                    value={formData.Current_Salary}
                                                    onChange={(e) => handleSalaryChange(e.target.name, e.target.value)}
                                                    min="0"
                                                />

                                            </li>
                                            <li><strong className={styles.strong}>Notice period<i className={styles.red}>*</i>:</strong>
                                                <div className={styles['div-right']}>

                                                    <SelectField
                                                        id="Notice_preiod"
                                                        name="Notice_preiod"
                                                        className={`${styles.select} ${formData.errors.Notice_preiod ? styles.error : ''}`}
                                                        value={formData.Notice_preiod}
                                                        options={notice}
                                                        error={formData.errors.Notice_preiod}
                                                        onChange={(e) => setFormData({ ...formData, Notice_preiod: e.target.value })}
                                                        defaultValue={formData.Notice_preiod ? formData.Notice_preiod : "Select Notice"}
                                                    />


                                                </div>
                                            </li>
                                            <li><strong className={styles.strong}>WHEN CAN YOU JOIN?<i className={styles.red}>*</i></strong>
                                                <div className={styles['div-right']}>


                                                    <SelectField
                                                        id="When_Join"
                                                        name="When_Join"
                                                        className={`${styles.select} ${formData.errors.When_Join ? styles.error : ''}`}
                                                        value={formData.When_Join}
                                                        options={Join}
                                                        error={!formData.When_Join.trim() ? 'This field is required' : ''}
                                                        onChange={(e) => setFormData({ ...formData, When_Join: e.target.value })}
                                                        defaultValue={formData.When_Join ? formData.When_Join : "Select Option"}
                                                    />


                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className={`${styles.declare} ${!isChecked ? styles.error : ''}`}>
                                    <label className={styles.checkbox}>I, hereby declare that the information furnished above is true, complete and correct to the best of my knowledge and belief.<i className={styles.red}>*</i>
                                        <input type="checkbox" className={styles.declare} id="Declare_Status" name="Declare_Status" onChange={handleCheckboxChange}  />
                                        <span className={styles.checkmark}
                                        ></span></label>



                                </div>
                                <div className={styles.clear}></div>
                            </div>
                        </form>
                        <Button
                            backButtonLabel="Back"
                            continueButtonLabel="Save & Preview"
                            onBackClick={handleBackClick}
                            onContinueClick={handleContinueClick}
                            backButtonClass={styles['custom-back-class']}
                            continueButtonClass={styles['custom-continue-class']}
                            previewButtonLabel={isEdit ? "Preview" : ""}
                            previewButtonAction={isEdit ? handlePreviewPage : ''}
                            disabled={isUploading}
                        />


                    </div>
                </>
            )}
            {showRreview && <Rreview step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} />}
        </>
    );

}
export default Language;
