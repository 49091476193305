import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import Logo from '../../assets/images/logo.png';
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
const styles = StyleSheet.create({
    table: {
        border: 'none',
        paddingTop: '20',
    },
    tables: {
        border: 'none',
        paddingTop: '10',
    },
    row: {
        border: 'none',
    },
    tableOverCell: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5'
    },
    textElement: {
        whiteSpace: 'nowrap',
        paddingRight: '3',
        fontSize: '9'
    },
    tableCell: {
        fontSize: '9',
        padding: '3'
    },
    cell: {
        border: 'none',
        padding: 8,
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
    },
    text: {
        margin: 12,
        fontSize: 9,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
    },
    image: {
        width: 100,
        height: 50,
        marginRight: 10,
    },
    candidateimage: {
        width: 150,
        height: 150,
        marginRight: 10,
    },
    candidatetext: {
        width: 150,
        height: 150,
        marginRight: 10,
        fontSize: 9,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        fontSize: 12,
        color: 'grey',
    },
    basicTABLE: {
        border: 'none',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    mainHeading: {
        backgroundColor: '#263746',
        borderRadius: 5,
        color: '#fff',
        fontSize: 10,
        fontWeight: '500',
        marginBottom: 8,
        padding: 5,
        position: 'relative',
        textTransform: 'uppercase',
        marginTop: '20',
    },
    mainHeadings: {
        backgroundColor: '#8e8e9345',
        borderRadius: 5,
        color: '#0e103d',
        fontSize: 9,
        fontWeight: '700',
        marginBottom: 8,
        textTransform: 'uppercase',
        marginTop: '15',
        padding: 5,
    },
    basictd: {
        width: "33%",
        whiteSpace: 'normal',
        padding: '10px',
        verticalAlign: 'top',
    },
    span: {
        color: '#000',
        display: 'block',
        fontSize: 9,
        fontWeight: '600',
        textAlign: 'left',
        textTransform: 'uppercase',
        border: 'none',
    },
    div: {
        backgroundColor: '#f2f2f208',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        verticalAlign: 'top',
    },
    inputText: {
        background: '#fff',
        borderRadius: '6',
        boxSizing: 'border-box',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        padding: '5px',
        outline: 'none',
        textAlign: 'left',
        transition: '0.5s linear',
        width: '100%',
        paddingLeft: '20px',
    },
    inputTexts: {
        background: '#fff',
        borderRadius: '6',
        boxSizing: 'border-box',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        outline: 'none',
        textAlign: 'left',
        transition: '0.5s linear',
        width: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
    },
    inputTextfield: {
        background: '#fff',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        paddingLeft: 80,
    },
    colSpan: {
        width: '100%',
    },
    declare: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10,
        fontSize: 9,
    },
    checkbox: {
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        fontSize: 9,
    },
    checkmark: {
        width: 20,
        height: 20,
        border: '1px solid black',
        backgroundColor: '#f5f5f5',
        marginRight: 5,
        fontSize: 9,
    },
    checkmarkChecked: {
        backgroundColor: 'black',
    },
    red: {
        color: 'red',
    },
    tableHeader: {
        fontSize: '11',
        padding: '3',
        fontWeight: 'Bold'
    }
});

const OffBoardingPdf = ({ blitz_user_id, token, setInfoData}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [empJobResponsibility, setEmpJobResponsibility] = useState([]);
    const [accountDetails, setAccountDetails] = useState([]);
    const [classification, setClassification] = useState([]);
    const [finalHrProcessnDetails, setFinalHrProcessnDetails] = useState([]);
    const [admin, setAdmin] = useState([]);
    const [it, setIt] = useState([]);
    const apiEndpoint = process.env.REACT_APP_API_URL + 'api/offboarding/view-offboarding-info';

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.post(
                    apiEndpoint,
                    { blitz_user_id: blitz_user_id },
                    {
                        headers: {
                            'token': token,
                            'accept': 'application/json',
                        },
                    }
                );
               
                setEmployeeDetails(response.data.info.employee_details);
                setEmpJobResponsibility(response.data.info.emp_job_responsibility)
                setClassification(response.data.info.classification);
                setFinalHrProcessnDetails(response.data.info.final_hr_process);
                setAdmin(response.data.info.admin);
                setAccountDetails(response.data.info.account)
                setIt(response.data.info.it);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch data. Please check your internet connection or API status.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiEndpoint]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }
    const { remove_software_access, remove_mobile_access } = classification || {};


    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.header} fixed>
                    <Image style={styles.image} src={Logo} />
                    <Text style={styles.title}>Employee Offboarding Details</Text>
                </View>

                {/* Employee Details */}
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee Personal Details</Text>
                </View>


                <Table style={styles.table}>
                    <TR>

                        <TD style={styles.tableHeader}>Fields</TD>
                        <TD style={styles.tableHeader}>Details</TD>


                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>First Name</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.first_name || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Last Name</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.last_name || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Job Title</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.job_title || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Department</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.department || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Date of Joining</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.date_of_joining || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Releaving Date</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.releaving_date || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Reason for Leaving</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.reason_for_leaving || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Manager</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.manager || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Personal Email</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.personal_email_address || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Street Address</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.street_address || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.comment || 'N/A'}</TD>
                    </TR>
                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee Job Responsibility</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Status</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Project Backups</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.project_backups ? 'Yes' : 'No'}</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Experience Working Here</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.experience_working_here || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Join Us Again</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.join_us_again ? 'Yes' : 'No'}</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Things You Liked</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.things_you_liked || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Things You Didn't Like</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.things_you_not_liked || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Timesheet Submitted</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.timesheet_submitted ? 'Yes' : 'No'}</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Dream Job</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.dream_job || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Special Qualifications</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.special_qualifications || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>ID Card Returned</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.id_card_returned ? 'Yes' : 'No'}</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>ID Card Returned Comment</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.id_card_returned_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Company Belongings Returned</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.company_belongings_returned ? 'Yes' : 'No'}</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Summarize Responsibilities</TD>
                        <TD style={{ ...styles.tableCell, whiteSpace: 'pre-line', wordWrap: 'break-word', overflow: 'auto' }}>{empJobResponsibility?.summarize_responsibilities || 'N/A'}</TD>
                    </TR>


                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Technologies List</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.technologies_list || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Suggestions to Improve</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.suggestions_to_improve || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Work Description</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.worked_describe || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>People You Would Like to Work With</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.people_you_would_like_to_work || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>No Join Reasons</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.no_join_reasons || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Like Features of BLUENT</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.like_features_of_BLUENT || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Not Like Features of BLUENT</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.not_like_features_of_BLUENT || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Performing Regular Duties</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.performing_regular_duties || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{empJobResponsibility?.comment || 'N/A'}</TD>
                    </TR>
                </Table>

                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee Classification Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Fields</TD>
                        <TD style={styles.tableHeader}>Details</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Project Handover</TD>
                        <TD style={styles.tableCell}>{classification?.project_handover || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Google Email</TD>
                        <TD style={styles.tableCell}>{classification?.google_email ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Inform Client</TD>
                        <TD style={styles.tableCell}>{classification?.inform_client ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Project Backup</TD>
                        <TD style={styles.tableCell}>{classification?.project_backup ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove Software Access</TD>
                        <TD style={styles.tableCell}>
                            {classification?.remove_software_access ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(classification.remove_software_access)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>No data available</Text>
                            )}
                        </TD>


                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Svn</TD>
                        <TD style={styles.tableCell}>{classification?.svn ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Inform client comment</TD>
                        <TD style={styles.tableCell}>{classification?.inform_client_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Project backup comment</TD>
                        <TD style={styles.tableCell}>{classification?.project_backup_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove software access comment</TD>
                        <TD style={styles.tableCell}>{classification?.remove_software_access_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Skype</TD>
                        <TD style={styles.tableCell}>{classification?.skype ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove client folder access</TD>
                        <TD style={styles.tableCell}>{classification?.remove_client_folder_access ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>System backup</TD>
                        <TD style={styles.tableCell}>{classification?.system_backup ? 'Yes' : 'No'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove Mobile Access</TD>
                        <TD style={styles.tableCell}>
                            <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>

                                {remove_mobile_access && typeof remove_mobile_access === 'object'
                                    ? Object.entries(remove_mobile_access)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')
                                    : 'No data available'}
                            </Text>

                        </TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Reason</TD>
                        <TD style={styles.tableCell}>{classification?.reason || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove client folder access comment</TD>
                        <TD style={styles.tableCell}>{classification?.remove_client_folder_access_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>System backup comment</TD>
                        <TD style={styles.tableCell}>{classification?.system_backup_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove mobile access comment</TD>
                        <TD style={styles.tableCell}>{classification?.remove_mobile_access_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Email Forward</TD>
                        <TD style={styles.tableCell}>{classification?.email_forward || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Email Backup</TD>
                        <TD style={styles.tableCell}>{classification?.email_backup ? 'Yes' : 'No'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Timesheet Approved</TD>
                        <TD style={styles.tableCell}>{classification?.timesheet_approved ? 'Yes' : 'No'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>logme</TD>
                        <TD style={styles.tableCell}>{classification?.logme ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Mail box</TD>
                        <TD style={styles.tableCell}>{classification?.mail_box || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Email backup comment</TD>
                        <TD style={styles.tableCell}>{classification?.email_backup_comment || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Rack space email</TD>
                        <TD style={styles.tableCell}>{classification?.rack_space_email ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Time duration</TD>
                        <TD style={styles.tableCell}>{classification?.time_duration ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{classification?.comment || 'N/A'}</TD>
                    </TR>
                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee IT Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Status</TD>
                        <TD style={styles.tableHeader}>Comment</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Email Forwarding</TD>
                        <TD style={styles.tableCell}>{it?.email_forward ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.email_forward_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Software Access (Office365)</TD>
                        <TD style={styles.tableCell}>
                            <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>

                                {it?.software_access_value && typeof it?.software_access_value === 'object'
                                    ? Object.entries(it?.software_access_value)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')
                                    : 'No data available'}
                            </Text>

                        </TD>
                        <TD style={styles.tableCell}>{it?.software_access_comment || '-'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Project Backup</TD>
                        <TD style={styles.tableCell}>{it?.project_backup ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.project_backup_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>PC Equipment</TD>
                        <TD style={styles.tableCell}>
                            <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>

                                {it?.pc_equipment_value && typeof it?.pc_equipment_value === 'object'
                                    ? Object.entries(it.pc_equipment_value)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')
                                    : 'No data available'}
                            </Text>

                        </TD>
                        <TD style={styles.tableCell}>{it?.pc_equipment_comment || 'No specific comment'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Mobile Access</TD>
                        <TD style={styles.tableCell}><Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>

                            {it?.mobile_access_value && typeof it?.mobile_access_value === 'object'
                                ? Object.entries(it.mobile_access_value)
                                    .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                    .join(', ')
                                : 'No data available'}
                        </Text></TD>
                        <TD style={styles.tableCell}>{it?.mobile_access_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>System Backup</TD>
                        <TD style={styles.tableCell}>{it?.system_backup ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.system_backup_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>AD Account Disabled</TD>
                        <TD style={styles.tableCell}>{it?.ad_account_disabled ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.ad_account_disabled_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Email Address Removed</TD>
                        <TD style={styles.tableCell}>{it?.email_address_removed ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.email_address_removed_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Email Forward to</TD>
                        <TD style={styles.tableCell}>{it?.email_forward_to ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.email_forward_to_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Google Email</TD>
                        <TD style={styles.tableCell}>{it?.google_email ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.google_email_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Mobile And SIM</TD>
                        <TD style={styles.tableCell}>{it?.mobile_and_SIM ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.mobile_and_SIM_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>MailBox</TD>
                        <TD style={styles.tableCell}>{it?.mail_box ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.mail_box_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Access Control</TD>
                        <TD style={styles.tableCell}>{it?.access_control ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.access_control_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Folder Permission</TD>
                        <TD style={styles.tableCell}>{it?.folder_permission ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.folder_permission_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove Client Folder Access</TD>
                        <TD style={styles.tableCell}>{it?.remove_client_folder_access ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.remove_client_folder_access_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Time Duration</TD>
                        <TD style={styles.tableCell}>{it?.time_duration ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.time_duration_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Email Backup</TD>
                        <TD style={styles.tableCell}>{it?.email_backup ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.email_backup_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Mail Baox To</TD>
                        <TD style={styles.tableCell}>{it?.mail_box_to ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{it?.mail_box_to_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>System Backup Classification Comment</TD>
                        <TD style={styles.tableCell}>{it?.system_backup_classification_comment || ''}</TD>
                        <TD style={styles.tableCell}>{'-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Email Backup Classification Comment</TD>
                        <TD style={styles.tableCell}>{it?.email_backup_classification_comment || ''}</TD>
                        <TD style={styles.tableCell}>{'-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Mobile Access Classification Comment</TD>
                        <TD style={styles.tableCell}>{it?.mobile_access_classification_comment || ''}</TD>
                        <TD style={styles.tableCell}>{'-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove Client Folder Access Classification Comment</TD>
                        <TD style={styles.tableCell}>{it?.remove_client_folder_access_classification_comment || ''}</TD>
                        <TD style={styles.tableCell}>{'-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Project Backup Classification Comment</TD>
                        <TD style={styles.tableCell}>{it?.project_backup_classification_comment || ''}</TD>
                        <TD style={styles.tableCell}>{'-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Software Access Classification Comment</TD>
                        <TD style={styles.tableCell}>{it?.software_access_classification_comment || ''}</TD>
                        <TD style={styles.tableCell}>{'-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{it?.comment || ''}</TD>
                        <TD style={styles.tableCell}>{'-'}</TD>
                    </TR>
                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee Admin Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Status</TD>
                        <TD style={styles.tableHeader}>Comment</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Books</TD>
                        <TD style={styles.tableCell}>{admin?.books ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{admin?.books_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Business Card Returned</TD>
                        <TD style={styles.tableCell}>{admin?.return_business_card ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{admin?.return_business_card_comment || '-'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>No Dues</TD>
                        <TD style={styles.tableCell}>{admin?.no_dues ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{admin?.no_dues_comment || 'No Comment'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>ID Card Returned</TD>
                        <TD style={styles.tableCell}>{admin?.id_card_returned ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{admin?.return_business_card_comment ? 'Not Applicable' : ''}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>ID Business Returned</TD>
                        <TD style={styles.tableCell}>{admin?.return_business_card ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{admin?.return_business_card_comment ? 'Not Applicable' : ''}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>ID Cart Returned Applicable</TD>
                        <TD style={styles.tableCell}>{admin?.id_card_returned_not_applicable ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{admin?.id_card_returned_not_applicable ? 'Not Applicable' : ''}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{admin?.comment || '-'}</TD>
                        <TD style={styles.tableCell}>{'-'}</TD>
                    </TR>
                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Account Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Status</TD>
                        <TD style={styles.tableHeader}>Comment</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>No Due</TD>
                        <TD style={styles.tableCell}>{accountDetails?.no_dues ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{accountDetails?.no_dues_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove From Salary List</TD>
                        <TD style={styles.tableCell}>{accountDetails?.remove_from_salary_list ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{accountDetails?.remove_from_salary_list_comment || '-'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{accountDetails?.comment || ''}</TD>
                        <TD style={styles.tableCell}>{accountDetails?.account_comment || '-'}</TD>
                    </TR>
                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee HR FInal Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Status</TD>
                        <TD style={styles.tableHeader}>Comment</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Notice Period</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.notice_period ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>IT Clearance</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.it ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.it_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Confidentiality Agreement Signed</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.confidentiality_agreement_signed ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>N/A</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Clearance Certificate Signed</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.clearance_certificate_signed ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>N/A</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Relieving Letter Issued</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.relieving_letter_issued ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.relieving_letter_issued_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Blitz Account Removed</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.blitz_account_removed ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>N/A</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Exit Interview</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.exit_interview ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.exit_interview_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Classification</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.classification ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.classification_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>No Dues</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.no_dues ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.no_dues_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Admin Clearance</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.admin ? 'Yes' : 'No'}</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.admin_comment || 'N/A'}</TD>
                    </TR>
                </Table>


            </Page>
        </Document>
    );
};

export default OffBoardingPdf;
