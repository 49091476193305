import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import OffBoadingEmployeeInfo from '../OffBoadingEmployeeInfo/OffBoadingEmployeeInfo';
import OffboadingEmployeeDetails from '../../api/FetchOffboadingEmployeeDetails';
import OffboadingSaveEmployeeDetails from '../../api/OffboadingSaveEmployeeDetails';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OffBoadingFinalHRProcess = ({ isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [formData, setFormData] = useState({
        notice_period: false,
        exit_interview: false,
        exit_interview_comment: '',
        comment: '',
        it: false,
        admin: false,
        it_comment: '',
        admin_comment: '',
        classification: false,
        classification_comment: '',
        confidentiality_agreement_signed: false,
        clearance_certificate_signed: false,
        blitz_account_removed: false,
        no_dues: false,
        no_dues_comment: "",
        relieving_letter_issued: false,
        relieving_letter_issued_comment: ""
    });

    const [formErrors, setFormErrors] = useState({
        exit_interview_comment: '',
        comment: '',
        it_comment: '',
        admin_comment: '',
        classification_comment: ''
    });

   



    useEffect(() => {
        const url = 'get-final-hr-process';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const employeeData = await OffboadingEmployeeDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.data;
                        setFormData({
                            notice_period: empDetails.notice_period || false,
                            exit_interview: empDetails.exit_interview || false,
                            exit_interview_comment: empDetails.exit_interview_comment || '',
                            comment: empDetails.comment || '',
                            it: empDetails.it || false,
                            admin: empDetails.admin || false,
                            it_comment: empDetails.it_comment || '',
                            admin_comment: empDetails.admin_comment || '',
                            classification: empDetails.classification || false,
                            classification_comment: empDetails.classification_comment || '',
                            confidentiality_agreement_signed: empDetails.confidentiality_agreement_signed || false,
                            clearance_certificate_signed: empDetails.clearance_certificate_signed || false,
                            blitz_account_removed: empDetails.blitz_account_removed || false,
                            no_dues: empDetails.no_dues || false,
                            no_dues_comment: empDetails.no_dues_comment || "",
                            relieving_letter_issued: empDetails.relieving_letter_issued || false,
                            relieving_letter_issued_comment: empDetails.relieving_letter_issued_comment || '',


                        });

                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }
                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId]);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked,
            // Reset related comment fields if unchecked
            it_comment: name === 'it' && !checked ? '' : prevState.it_comment,
            admin_comment: name === 'admin' && !checked ? '' : prevState.admin_comment
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const validateForm = () => {
        let valid = true;
        const errors = {
            exit_interview_comment: '',
            comment: '',
            it_comment: '',
            admin_comment: ''
        };

        if (formData.exit_interview && !formData.exit_interview_comment) {
            toast.error('Exit interview comment is required');
            valid = false;
        }

        if (!formData.comment) {
            toast.error('Comment is required');
            valid = false;
        }

        // Validate it_comment if it is mandatory
        if (formData.it && !formData.it_comment) {
            toast.error('IT comment is required');
            valid = false;
        }

        if (formData.admin && !formData.admin_comment) {
            toast.error('Administrative comment is required');
            valid = false;
        }
        if (formData.classification && !formData.classification_comment) {
            toast.error('Classification comment is required');
            valid = false;
        }

        setFormErrors(errors);
        return valid;
    };

    const handleSaveInfo = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        setLoading(true);


        try {
            setLoading(true);
            const url = 'final-hr-process';
            setLoading(true);
            const requestData = {
                ...formData,
                status: "Pending",
                created_by: admin,
            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);

            toast.success(response.msg);

        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        setLoading(true);


        try {
            setLoading(true);
            const url = 'final-hr-process';
            setLoading(true);
            const requestData = {
                ...formData,
                status: "Submitted",
                created_by: admin,
            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);

            toast.success(response.msg);
            setButtonsDisabled(true);

        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            //setButtonsDisabled(true);
            setLoading(false);
        }
    };

    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}></div>

                <OffBoadingEmployeeInfo token={token} employeeId={employeeId} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={`${styles.fieldset} ${buttonsDisabled ? styles['fieldset-disabled'] : ''}`}>
                            <legend>Final HR Process</legend>
                            <div className={styles.grid} style={{ marginTop: "10px" }}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>Served Notice Period:</strong>
                                                <span>
                                                    <input type="checkbox" id="noticePeriod" name="notice_period" checked={formData.notice_period}
                                                        onChange={handleCheckboxChange} disabled={buttonsDisabled} />
                                                    <label htmlFor="noticePeriod"></label>
                                                </span>
                                            </li>
                                            <li><strong>Confidentiality Agreement Signed:</strong>
                                                <span>
                                                    <input type="checkbox" id="Agreement" name="confidentiality_agreement_signed" value="Agreement" checked={formData.confidentiality_agreement_signed} onChange={handleCheckboxChange} disabled={buttonsDisabled} />
                                                    <label htmlFor="Agreement"></label>
                                                </span></li>

                                            <li><strong>Blitz account removed:</strong>
                                                <span>
                                                    <input type="checkbox" id="blitz_account_removed" name="blitz_account_removed" value="blitz_account_removed" checked={formData.blitz_account_removed} onChange={handleCheckboxChange} disabled={buttonsDisabled} />
                                                    <label htmlFor="blitz_account_removed"></label>
                                                </span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={`${styles['two-col']}  `}>
                                            <li>
                                                <strong>Exit Interview:</strong>
                                                <span>
                                                    <input type="checkbox" id="exitInterview" name="exit_interview" checked={formData.exit_interview}
                                                        onChange={handleCheckboxChange} disabled={buttonsDisabled} />
                                                    <label htmlFor="exitInterview"></label>
                                                    {formData.exit_interview && (
                                                        <textarea name="exit_interview_comment" className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`} value={formData.exit_interview_comment} placeholder="Exit Interview Comment" onChange={handleChange} disabled={buttonsDisabled}></textarea>
                                                    )}
                                                </span>
                                                {formErrors.exit_interview_comment && <div className={styles['empl-error']}>{formErrors.exit_interview_comment}</div>}
                                            </li>

                                            <li><strong>Clearance Certificate Signed:</strong>
                                                <span>
                                                    <input type="checkbox" id="Clearance" name="clearance_certificate_signed" value="Clearance" checked={formData.clearance_certificate_signed} onChange={handleCheckboxChange} disabled={buttonsDisabled} />
                                                    <label htmlFor="Clearance"></label>
                                                </span></li>

                                            <li>
                                                <strong>No Dues:</strong>
                                                <span>
                                                    <input type="checkbox" id="no_dues" name="no_dues" checked={formData.no_dues}
                                                        onChange={handleCheckboxChange} disabled={buttonsDisabled} />
                                                    <label htmlFor="no_dues"></label>
                                                    {formData.no_dues && (
                                                        <textarea name="no_dues_comment" className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`} value={formData.no_dues_comment} placeholder="No dues Comment" onChange={handleChange} disabled={buttonsDisabled}></textarea>
                                                    )}
                                                </span>
                                                {formErrors.no_dues_comment && <div className={styles['empl-error']}>{formErrors.no_dues_comment}</div>}
                                            </li>
                                            <li>
                                                <strong>Relieving Letter Issued:</strong>
                                                <span>
                                                    <input type="checkbox" id="relieving_letter_issued" name="relieving_letter_issued" checked={formData.relieving_letter_issued}
                                                        onChange={handleCheckboxChange} disabled={buttonsDisabled} />
                                                    <label htmlFor="relieving_letter_issued"></label>
                                                    {formData.relieving_letter_issued && (
                                                        <textarea name="relieving_letter_issued_comment" className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`} value={formData.relieving_letter_issued_comment} placeholder="Relieving letter issue Comment" onChange={handleChange} disabled={buttonsDisabled}></textarea>
                                                    )}
                                                </span>
                                                {formErrors.relieving_letter_issued_comment && <div className={styles['empl-error']}>{formErrors.relieving_letter_issued_comment}</div>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <fieldset className={`${styles.col8} ${styles['final-space']} `} style={{ marginLeft: '20px' }}>
                                    <legend>IT</legend>
                                    <div>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>Verified:</strong>
                                                <span className={styles.calling}>
                                                    <input
                                                        type="checkbox"
                                                        id="it"
                                                        name="it"
                                                        checked={formData.it}
                                                        onChange={handleCheckboxChange}
                                                        disabled={buttonsDisabled}
                                                    />
                                                    <label htmlFor="it"></label>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Comment:</strong>
                                                <span>
                                                    <textarea
                                                        name="it_comment"
                                                        className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`}
                                                        value={formData.it_comment}
                                                        onChange={handleChange}
                                                        disabled={!formData.it || buttonsDisabled} e
                                                        required={formData.it}
                                                    ></textarea>
                                                </span>
                                                {formErrors.it_comment && <div className={styles['empl-error']}>{formErrors.it_comment}</div>}
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                                <fieldset className={`${styles.col8} ${styles['final-space']} `}>
                                    <legend>Administrative</legend>
                                    <div>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>Verified:</strong>
                                                <span className={styles.calling}>
                                                    <input
                                                        type="checkbox"
                                                        id="administrative"
                                                        name="admin"
                                                        checked={formData.admin}
                                                        onChange={handleCheckboxChange}
                                                        disabled={buttonsDisabled}
                                                    />
                                                    <label htmlFor="administrative"></label>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Comment:</strong>
                                                <span>
                                                    <textarea
                                                        name="admin_comment"
                                                        className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`}
                                                        value={formData.admin_comment}
                                                        onChange={handleChange}
                                                        disabled={!formData.admin || buttonsDisabled}
                                                        required={formData.admin}
                                                    ></textarea>
                                                </span>
                                                {formErrors.admin_comment && <div className={styles['empl-error']}>{formErrors.admin_comment}</div>}
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                                <fieldset className={`${styles.col8} ${styles['final-space']} `}>
                                    <legend>Classification</legend>
                                    <div>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>Verified:</strong>
                                                <span className={styles.calling}>
                                                    <input
                                                        type="checkbox"
                                                        id="classification"
                                                        name="classification"
                                                        checked={formData.classification}
                                                        onChange={handleCheckboxChange}
                                                        disabled={buttonsDisabled}
                                                    />
                                                    <label htmlFor="classification"></label>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Comment:</strong>
                                                <span>
                                                    <textarea
                                                        name="classification_comment"
                                                        className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`}
                                                        value={formData.classification_comment}
                                                        onChange={handleChange}
                                                        disabled={!formData.classification || buttonsDisabled}
                                                        required={formData.classification}

                                                    ></textarea>
                                                </span>
                                                {formErrors.classification_comment && <div className={styles['empl-error']}>{formErrors.classification_comment}</div>}
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                               
                                <div className={styles['right-space']}>
                                    <ul className={`${styles['two-col']}  `}>
                                        <li>
                                            <strong>Comment<i className={styles['red']}>*</i>:</strong>
                                            <span>
                                                <textarea name="comment" className={`${styles.input} ${buttonsDisabled ? styles['disabled-field'] : ''}`} value={formData.comment} onChange={handleChange} disabled={buttonsDisabled}></textarea>
                                            </span>
                                            {formErrors.comment && <div className={styles['empl-error']}>{formErrors.comment}</div>}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </fieldset>
                        {/* Button block */}
                        <div className={styles['button-block']}>
                            {!buttonsDisabled && (
                                <input type="submit" value="Save" className={styles.button} onClick={handleSaveInfo} disabled={loading || buttonsDisabled} />
                            )}
                            {!buttonsDisabled && (
                                <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={handleSubmit} disabled={loading || buttonsDisabled} />

                            )}
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OffBoadingFinalHRProcess;
