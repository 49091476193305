import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const IT = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [trueOrNonEmptyFields, setTrueOrNonEmptyFields] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [specialSoftware, setSpecialSoftware] = useState('');
    const [additionalAccess, setAdditionalAccess] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [errorIndex, setErrorIndex] = useState(0);
    const [errorMessages, setErrorMessages] = useState([]);
    const tab = 'IT';
    const [rejectComment, setRejectComment] = useState('');
    const [isCommentSubmitted, setIsCommentSubmitted] = useState(false);
    const [pc_equipmentTest, setPCEquipmentTest] = useState(null);
    const [mobileAccessTest, setMobileAccessTest] = useState(null);
    const [softwareAccessTest, setsoftwareAccessTest] = useState(null);
    
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
    
        //specialSoftwareNeeds: '',
        additional_access: false,
        additional_access_name: '',
        //ship_laptop: false,
        other_location: '',
        network_calling: false,
        whichCountry: false,
        selectedSoftwares: [],
        special_software_needs: false,
        special_software_name: '',
        business_card_required: false,
        security_code: false,
        security_info: false,
        shipment_information: '',
        security_information_sent: false,
        security_code: false,
        country: '',
        comment: '',
        laptop_ready_for_shipment: false,
        selectedAccessNames: [],
        laptop_ready_for_shipment_show: false
    });
    const [pc_equipment, setPCEquipment] = useState({
        headphone: false,
        desktop: false,
        mouse: false,
        monitor: false,
        keyboard: false,
        laptop: false
    });
    const [softwareAccess, setSoftwareAccess] = useState({
        office_365: false,
        //jira: false,
        dropbox: false,
        //git: false,
        //quicksight: false,
        //powerbi: false,
        // crm: false,
        //winrar: false,
        autocad_aec: false,
        autocad_lt: false,
        adobe_creative_suite: false,
        vray: false,
        bluebeam: false,
        atlassian_cloud: false,
        hubspot: false,
        google_cloud: false,
        roboform: false,
        blitz: false,
        //atlassian: false,
        zoho: false
    });
    const [mobileAccess, setmobileAccess] = useState({
        teams: false,
        outlook: false,
        jira_software: false
    });

    const redirectToDashboard = () => {
        const searchParams = new URLSearchParams(window.location.search);
        let token = searchParams.get('token');
        navigate('/dashboard?token=' + token + '&activeTab=1');
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handlepcCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPCEquipment(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const handleswCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSoftwareAccess(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handlemobileCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setmobileAccess(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCommentChange = (event) => {
        setRejectComment(event.target.value);
    };

    const handleReject = (event) => {
        event.preventDefault();
        setIsPopupOpen(true);
    };

    useEffect(() => {
        const url = 'get-employee-it-information';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.it;
                        const trueFields = Object.keys(empDetails).filter(key => empDetails[key] === true);
                        const getTrueOrNonEmptyFields = (obj) => {
                            const trueOrNonEmptyFields = [];
                            for (const key in obj) {
                                if (obj.hasOwnProperty(key)) {
                                    const value = obj[key];
                                    if (value === true || (typeof value === 'string' && value.trim() !== '')) {
                                        trueOrNonEmptyFields.push(key);
                                    } else if (typeof value === 'object' && value !== null) {
                                        const nestedTrueOrNonEmptyFields = getTrueOrNonEmptyFields(value);
                                        trueOrNonEmptyFields.push(...nestedTrueOrNonEmptyFields.map(field => key + '.' + field));
                                    }
                                }
                            }
                            return trueOrNonEmptyFields;
                        };

                        let trueOrNonEmptyFields = getTrueOrNonEmptyFields(empDetails);

                        const fieldsToRemove = ["created_by", "created_at", "status", "update_first", "created_date"];
                        trueOrNonEmptyFields = trueOrNonEmptyFields.filter(field => !fieldsToRemove.includes(field));




                        const initialFormData = {
                            //pcEquipment: empDetails.pcEquipment || '',
                            //softwareAccess: empDetails.softwareAccess || '',
                            //mobile_access: empDetails.mobile_access || '',
                            laptop_ready_for_shipment: empDetails.laptop_ready_for_shipment || false,
                            laptop_ready_for_shipment_show: empDetails.laptop_ready_for_shipment_show || false,
                            special_software_needs: empDetails.special_software_needs || '',

                            ship_laptop: empDetails.ship_laptop || '',
                            other_location: empDetails.other_location || '',
                            network_calling: empDetails.network_calling || '',
                            country: empDetails.country || '',
                            business_card_required: empDetails.business_card_required || '',
                            security_code: empDetails.security_code || false,
                            security_info: empDetails.security_info || false,
                            comment: empDetails.comment || '',
                            additional_access: empDetails.additional_access || '',
                            shipment_information: empDetails.shipment_information || '',
                            security_information_sent: empDetails.security_information_sent || false,
                            update_first: empDetails.update_first || '',
                            user_name: empDetails.user_name || '',
                            classification_comment:empDetails.classification_comment || ''
                        }
                        setCreatedBy(empDetails.classification_created_by || '');
                        setSpecialSoftware(empDetails.special_software_name || '');
                       
                    
                        setAdditionalAccess(empDetails.additional_access_name || '',);
                        const validationState = {
                            pc_equipment: !!empDetails.pc_equipment,
                            softwareAccess: !!empDetails.softwareAccess,
                            mobile_access: !!empDetails.mobile_access,
                            special_software_needs: !!empDetails.special_software_needs,
                            special_software_name: !!empDetails.special_software_name,
                            ship_laptop: !!empDetails.ship_laptop,
                            other_location: !!empDetails.other_location,
                            network_calling: !!empDetails.network_calling,
                            country: !!empDetails.country,
                            business_card_required: !!empDetails.business_card_required,
                            security_code: !!empDetails.security_code,
                            security_info: !!empDetails.security_info,
                            comment: !!empDetails.comment,
                            additional_access: !!empDetails.additional_access,
                            additional_access_name: !!empDetails.additional_access_name,
                            shipment_information: !!empDetails.shipment_information,
                            security_information_sent: !!empDetails.security_information_sent
                        };

                        if (initialFormData.update_first === 'First') {
                            if (initialFormData.security_information_sent === true) {
                                initialFormData.security_information_sent = false;
                            }
                            else {
                                initialFormData.security_information_sent = '';
                            }
                            if (initialFormData.security_info === true) {
                                initialFormData.security_info = false;
                            }
                            else {
                                initialFormData.security_info = '';
                            }
                            if (initialFormData.security_code === true) {
                                initialFormData.security_code = false;
                            }
                            else {
                                initialFormData.security_code = '';
                            }
                            if (initialFormData.business_card_required === true) {
                                initialFormData.business_card_required = false;
                            }
                            else {
                                initialFormData.business_card_required = '';
                            }
                            if (initialFormData.network_calling === true) {
                                initialFormData.network_calling = false;
                            }
                            else {
                                initialFormData.network_calling = '';
                            }
                            if (initialFormData.ship_laptop === true) {
                                initialFormData.ship_laptop = false;
                            }
                            else {
                                initialFormData.ship_laptop = '';
                            }
                            if (initialFormData.additional_access === true) {
                                initialFormData.additional_access = false;
                            }
                            else {
                                initialFormData.additional_access = '';
                            }
                            if (initialFormData.special_software_needs === true) {
                                initialFormData.special_software_needs = false;
                            }
                            else {
                                initialFormData.special_software_needs = '';
                            }





                        }

                        const pc_equipment = empDetails.pc_equipment;
                        console.log(pc_equipment);
                        setPCEquipmentTest(pc_equipment);

                        if (pc_equipment) {
                            if (initialFormData.update_first === 'First') {

                                setPCEquipment({
                                    desktop: pc_equipment.desktop ? false : '',
                                    headphone: pc_equipment.headphone ? false : '',
                                    laptop: pc_equipment.laptop ? false : '',
                                    mouse: pc_equipment.mouse ? false : '',
                                    monitor: pc_equipment.monitor ? false : '',
                                    keyboard: pc_equipment.keyboard ? false : ''
                                });

                            }
                            else if (initialFormData.update_first === 'Save' || initialFormData.update_first === 'Submitted') {

                                setPCEquipment({
                                    desktop: pc_equipment.desktop !== undefined ? pc_equipment.desktop : '',
                                    headphone: pc_equipment.headphone !== undefined ? pc_equipment.headphone : '',
                                    laptop: pc_equipment.laptop !== undefined ? pc_equipment.laptop : '',
                                    mouse: pc_equipment.mouse !== undefined ? pc_equipment.mouse : '',
                                    monitor: pc_equipment.monitor !== undefined ? pc_equipment.monitor : '',
                                    keyboard: pc_equipment.keyboard !== undefined ? pc_equipment.keyboard : ''
                                });
                            }

                        }

                        const mobileAccess = empDetails.mobile_access;
                        setMobileAccessTest(mobileAccess);
                        if (mobileAccess) {
                            if (initialFormData.update_first === 'First') {
                                setmobileAccess({
                                    teams: mobileAccess.teams ? false : '',
                                    outlook: mobileAccess.outlook ? false : '',
                                    jira_software: mobileAccess.jira_software ? false : ''

                                });
                            }
                            else if (initialFormData.update_first === 'Save' || initialFormData.update_first === 'Submitted') {
                                setmobileAccess({
                                    teams: mobileAccess.teams !== undefined ? mobileAccess.teams : '',
                                    outlook: mobileAccess.outlook !== undefined ? mobileAccess.outlook : '',
                                    jira_software: mobileAccess.jira_software !== undefined ? mobileAccess.jira_software : ''
                                });
                            }

                        }
                        const softwareAccess = empDetails.software_access;
                        setsoftwareAccessTest(softwareAccess);
                        if (softwareAccess) {
                            if (initialFormData.update_first === 'First') {
                                setSoftwareAccess({
                                    office_365: softwareAccess.office_365 ? false : '',
                                    //jira: softwareAccess.jira ? false : '',
                                    dropbox: softwareAccess.dropbox ? false : '',
                                    //git: softwareAccess.git ? false : '',
                                    //quicksight: softwareAccess.quicksight ? false : '',
                                    //powerbi: softwareAccess.powerbi ? false : '',
                                    //crm: softwareAccess.crm ? false : '',
                                    //winrar: softwareAccess.winrar ? false : '',
                                    autocad_aec: softwareAccess.autocad_aec ? false : '',
                                    autocad_lt: softwareAccess.autocad_lt ? false : '',
                                    adobe_creative_suite: softwareAccess.adobe_creative_suite ? false : '',
                                    vray: softwareAccess.vray ? false : '',
                                    bluebeam: softwareAccess.bluebeam ? false : '',
                                    atlassian_cloud: softwareAccess.atlassian_cloud ? false : '',
                                    hubspot: softwareAccess.hubspot ? false : '',
                                    google_cloud: softwareAccess.google_cloud ? false : '',
                                    roboform: softwareAccess.roboform ? false : '',
                                    blitz: softwareAccess.blitz ? false : '',
                                    //atlassian: softwareAccess.atlassian ? false : '',
                                    zoho: softwareAccess.zoho ? false : '',
                                });
                            }
                            else if (initialFormData.update_first === 'Save' || initialFormData.update_first === 'Submitted') {
                                setSoftwareAccess({
                                    office_365: softwareAccess.office_365 !== undefined ? softwareAccess.office_365 : '',
                                    //jira: softwareAccess.jira !== undefined ? softwareAccess.jira : '',
                                    dropbox: softwareAccess.dropbox !== undefined ? softwareAccess.dropbox : '',
                                    //git: softwareAccess.git !== undefined ? softwareAccess.git : '',
                                    //quicksight: softwareAccess.quicksight !== undefined ? softwareAccess.quicksight : '',
                                    //powerbi: softwareAccess.powerbi !== undefined ? softwareAccess.powerbi : '',
                                    //crm: softwareAccess.crm !== undefined ? softwareAccess.crm : '',
                                    //winrar: softwareAccess.winrar !== undefined ? softwareAccess.winrar : '',
                                    autocad_aec: softwareAccess.autocad_aec !== undefined ? softwareAccess.autocad_aec : '',
                                    autocad_lt: softwareAccess.autocad_lt !== undefined ? softwareAccess.autocad_lt : '',
                                    adobe_creative_suite: softwareAccess.adobe_creative_suite !== undefined ? softwareAccess.adobe_creative_suite : '',
                                    vray: softwareAccess.vray !== undefined ? softwareAccess.vray : '',
                                    bluebeam: softwareAccess.bluebeam !== undefined ? softwareAccess.bluebeam : '',
                                    atlassian_cloud: softwareAccess.atlassian_cloud !== undefined ? softwareAccess.atlassian_cloud : '',
                                    hubspot: softwareAccess.hubspot !== undefined ? softwareAccess.hubspot : '',
                                    google_cloud: softwareAccess.google_cloud !== undefined ? softwareAccess.google_cloud : '',
                                    roboform: softwareAccess.roboform !== undefined ? softwareAccess.roboform : '',
                                    blitz: softwareAccess.blitz !== undefined ? softwareAccess.blitz : '',
                                    //atlassian: softwareAccess.atlassian !== undefined ? softwareAccess.atlassian : '',
                                    zoho: softwareAccess.zoho !== undefined ? softwareAccess.zoho : ''
                                });

                            }

                        }

                        setFormData(initialFormData);
                        setTrueOrNonEmptyFields(trueOrNonEmptyFields);

                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }

                        setLoading(false);

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId]);

    const validateNestedFields = (fields, obj) => {
        for (const key of fields) {
            const keys = key.split('.');
            let value = obj;
            for (const k of keys) {
                value = value[k];
                if (value === undefined) break;
            }
            if (value === undefined || value === '' || value === false) {

                return false;
            }
        }
        return true;
    };

    const validateFields = (fields, obj) => {
        const fieldsToExclude = ['special_software_name', 'additional_access_name', 'classification_created_by'];

        const nestedFields = fields.filter(field => field.includes('.'));
        const nonNestedFields = fields.filter(field =>
            !field.includes('.') && !fieldsToExclude.includes(field)
        );

        // Check if pc_equipmentTest is not empty before running validation

        if (Object.values(pc_equipmentTest).some(v => v !== "")) {

            if ('laptop' in pc_equipment || 'desktop' in pc_equipment) {
                if ('laptop' in pc_equipment && pc_equipment.laptop === false) {
                    return 'Please select the Laptop option.';
                }

                if ('desktop' in pc_equipment && pc_equipment.desktop === false) {
                    return 'Please select the Desktop option.';
                }

                if ('laptop' in pc_equipment && 'desktop' in pc_equipment &&
                    (pc_equipment.laptop === false || pc_equipment.desktop === false)) {
                    return 'Please select both Laptop and Desktop options.';
                }
            }
        }


        // Check if softwareAccessTest is not empty before running validation
        if (Object.values(softwareAccessTest).some(v => v !== "")) {
            const hasFalseSoftwareAccess = Object.values(softwareAccess).some(v => v === false);
            if (hasFalseSoftwareAccess) {
                return 'Please select all Software Access options.';
            }
        }

        // Check if mobileAccessTest is not empty before running validation
        if (Object.values(mobileAccessTest).some(v => v !== "")) {
            const allMobileAccessSelected = Object.values(mobileAccess).some(v => v === false);
            if (allMobileAccessSelected) {
                return 'Please select all Mobile Access options.';
            }
        }

        // Validate non-nested fields
        for (const key of nonNestedFields) {
            if (obj[key] === undefined || obj[key] === '' || obj[key] === false) {
                return `Please fill out the field: ${key}`;
            }
        }

        // if (!obj.selectedAccessNames || obj.selectedAccessNames.length === 0) {
        // return 'At least one Additional Access must be selected.';
        // }

        //if (!obj.selectedSoftwares || obj.selectedSoftwares.length === 0) {
        //return 'At least one Special Software must be selected.';
        // }

        // Validate nested fields
        const selectedNestedFields = nestedFields.filter(key => {
            const nestedValue = key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj);
            return nestedValue === true;
        });

        return null;
    };





    const handlespecialCheckboxChange = (e, type) => {
        const { value, checked } = e.target;
        setFormData((prev) => {
            const selected = type === 'software'
                ? (prev.selectedSoftwares || [])
                : (prev.selectedAccessNames || []);

            if (checked) {
                return {
                    ...prev,
                    [type === 'software' ? 'selectedSoftwares' : 'selectedAccessNames']: [...selected, value],
                };
            } else {
                return {
                    ...prev,
                    [type === 'software' ? 'selectedSoftwares' : 'selectedAccessNames']: selected.filter(item => item !== value),
                };
            }
        });
    };

    const softwareArray = specialSoftware ? specialSoftware.split(',').map(software => software.trim()) : [];
    const accessArray = additionalAccess ? additionalAccess.split(',').map(access => access.trim()) : [];

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }

        try {
            setLoading(true);
            const url = 'save-employee-it-information';
            const selectedAccessString = formData.selectedAccessNames ? formData.selectedAccessNames.join(', ') : '';
            const selectedSoftwareString = formData.selectedSoftwares ? formData.selectedSoftwares.join(', ') : '';

            const requestData = {
                employee_id: employeeId,
                it: {
                    ...formData,
                    network_calling: formData.network_calling === '' ? false : formData.network_calling,
                    security_information_sent: formData.security_information_sent === '' ? false : formData.security_information_sent,
                    security_info: formData.security_info === '' ? false : formData.security_info,
                    security_code: formData.security_code === '' ? false : formData.security_code,
                    business_card_required: formData.business_card_required === '' ? false : formData.business_card_required,
                    additional_access: formData.additional_access === '' ? false : formData.additional_access,
                    special_software_needs: formData.special_software_needs === '' ? false : formData.special_software_needs,
                    ship_laptop: formData.ship_laptop === '' ? false : formData.ship_laptop,
                    laptop_ready_for_shipment: formData.laptop_ready_for_shipment === '' ? false : formData.laptop_ready_for_shipment,
                    pc_equipment: {
                        desktop: pc_equipment.desktop,
                        headphone: pc_equipment.headphone,
                        mouse: pc_equipment.mouse,
                        monitor: pc_equipment.monitor,
                        laptop: pc_equipment.laptop,
                        keyboard: pc_equipment.keyboard
                    },
                    software_access: {
                        office_365: softwareAccess.office_365,
                        //jira: softwareAccess.jira,
                        dropbox: softwareAccess.dropbox,
                        //git: softwareAccess.git,
                        // quicksight: softwareAccess.quicksight,
                        //powerbi: softwareAccess.powerbi,
                        //crm: softwareAccess.crm,
                        //winrar: softwareAccess.winrar,
                        autocad_aec: softwareAccess.autocad_aec,
                        autocad_lt: softwareAccess.autocad_lt,
                        adobe_creative_suite: softwareAccess.adobe_creative_suite,
                        vray: softwareAccess.vray,
                        bluebeam: softwareAccess.bluebeam,
                        atlassian_cloud: softwareAccess.atlassian_cloud,
                        hubspot: softwareAccess.hubspot,
                        google_cloud: softwareAccess.google_cloud,
                        roboform: softwareAccess.roboform,
                        blitz: softwareAccess.blitz,
                        //atlassian: softwareAccess.atlassian,
                        zoho: softwareAccess.zoho
                    },
                    mobile_access: {
                        teams: mobileAccess.teams,
                        jira_software: mobileAccess.jira_software,
                        outlook: mobileAccess.outlook
                    },
                    additional_access_names: selectedAccessString,
                    special_software_name: selectedSoftwareString,
                    update_first: "Save",
                    status: "Pending",
                    created_by: admin,
                }
            };



            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            if (!response.form_submitted) {
                toast.error(response.msg);

            }
            else {


                toast.success(response.msg);
            }
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }



    const hanldeSubmit = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        if (formData.laptop_ready_for_shipment_show && !formData.laptop_ready_for_shipment) {
            toast.error('Please Provide Laptop is ready and handed over to admin!');
            return;
        }
        if (formData.country && !formData.network_calling) {
            toast.error('Please Provide Network Calling!');
            return;
        }
        if (softwareArray.length > 0 && !formData.special_software_needs) {
            toast.error('Please Provide Special Software Needs!');
            return;
        }
        if (accessArray.length > 0 && !formData.additional_access) {
            toast.error('Please Provide Additional Access');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-it-information';
            const selectedAccessString = formData.selectedAccessNames ? formData.selectedAccessNames.join(', ') : '';
            const selectedSoftwareString = formData.selectedSoftwares ? formData.selectedSoftwares.join(', ') : '';
            const requestData = {
                employee_id: employeeId,
                it: {

                    ...formData,
                    network_calling: formData.network_calling === '' ? false : formData.network_calling,
                    security_information_sent: formData.security_information_sent === '' ? false : formData.security_information_sent,
                    security_info: formData.security_info === '' ? false : formData.security_info,
                    security_code: formData.security_code === '' ? false : formData.security_code,
                    business_card_required: formData.business_card_required === '' ? false : formData.business_card_required,
                    additional_access: formData.additional_access === '' ? false : formData.additional_access,
                    special_software_needs: formData.special_software_needs === '' ? false : formData.special_software_needs,
                    ship_laptop: formData.ship_laptop === '' ? false : formData.ship_laptop,
                    laptop_ready_for_shipment: formData.laptop_ready_for_shipment === '' ? false : formData.laptop_ready_for_shipment,
                    pc_equipment: {
                        desktop: pc_equipment.desktop,
                        headphone: pc_equipment.headphone,
                        mouse: pc_equipment.mouse,
                        monitor: pc_equipment.monitor,
                        laptop: pc_equipment.laptop,
                        keyboard: pc_equipment.keyboard
                    },
                    software_access: {
                        office_365: softwareAccess.office_365,
                        //jira: softwareAccess.jira,
                        dropbox: softwareAccess.dropbox,
                        //git: softwareAccess.git,
                        //quicksight: softwareAccess.quicksight,
                        //powerbi: softwareAccess.powerbi,
                        //crm: softwareAccess.crm,
                        //winrar: softwareAccess.winrar,
                        autocad_aec: softwareAccess.autocad_aec,
                        autocad_lt: softwareAccess.autocad_lt,
                        adobe_creative_suite: softwareAccess.adobe_creative_suite,
                        vray: softwareAccess.vray,
                        bluebeam: softwareAccess.bluebeam,
                        atlassian_cloud: softwareAccess.atlassian_cloud,
                        hubspot: softwareAccess.hubspot,
                        google_cloud: softwareAccess.google_cloud,
                        roboform: softwareAccess.roboform,
                        blitz: softwareAccess.blitz,
                        //atlassian: softwareAccess.atlassian,
                        zoho: softwareAccess.zoho
                    },
                    mobile_access: {
                        teams: mobileAccess.teams,
                        jira_software: mobileAccess.jira_software,
                        outlook: mobileAccess.outlook
                    },

                    additional_access_names: selectedAccessString,
                    special_software_name: selectedSoftwareString,
                    update_first: "Submitted",
                    status: "Submitted",
                    created_by: admin,
                }
            };

            const fieldsToValidate = trueOrNonEmptyFields.filter(field =>
                !['created_by', 'created_at', 'status', 'update_first', 'created_date'].includes(field)
            );

            const validationError = validateFields(fieldsToValidate, requestData.it);
            if (validationError) {
                setErrorMessages([validationError]);
                toast.error(validationError);
                setLoading(false);
                return;
            }

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            if (!response.form_submitted) {
                toast.error(response.msg);
            }
            else {
                setButtonsDisabled(true)
                toast.success(response.msg);
            }
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {

            setLoading(false);
        }
    }

    const closePopup = () => {
        setIsPopupOpen(false);
    }


    const rejectPopup = async () => {
        if (!rejectComment.trim()) {
            toast.error('Comment is required to reject', {
                duration: 4000,
            });
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/onboarding/reject-employee-it-information`,
                {
                    employee_id: employeeId,
                    comment: rejectComment,
                    type: 'OnBoarding IT',
                },
                {
                    headers: {
                        'token': token,
                        'accept': 'application/json',
                    },
                }
            );

            if (response) {
                toast.success(response.data.msg);
                setIsPopupOpen(false);
                setIsCommentSubmitted(true);
                setTimeout(() => {
                    redirectToDashboard();
                }, 2000);

            } else {
                toast.error('There is no token for selected candidate', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }
    };


    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}>

                </div>

                <EmployeeInfo token={token} employeeId={employeeId} createdBy={createdBy}  tab={tab} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={`${styles.fieldset} ${buttonsDisabled ? styles['fieldset-disabled'] : ''}`}>
                            <legend style={{ paddingBottom: "20px" }}>Information Technology</legend>
                            <div className={styles.grid}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            <li><strong>User Name:</strong> <span><input type="text" className={styles.input} style={{
                                                backgroundColor: '#f0f0f0',
                                                border: '1px solid #ccc',
                                                cursor: 'not-allowed'
                                            }} value={formData.user_name} disbaled /></span></li>
                                            {(pc_equipmentTest && ((pc_equipment.headphone !== '') || (pc_equipment.laptop !== '') || (pc_equipment.monitor !== '') || (pc_equipment.mouse !== '') || (pc_equipment.desktop !== '') || (pc_equipment.keyboard !== ''))) && (
                                                <li>
                                                    <strong>PC Equipment<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        {pc_equipment.desktop !== '' &&
                                                            <>
                                                                <input type="checkbox" id="desktop" name="desktop" value="desktop" checked={pc_equipment.desktop} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="desktop">Desktop</label>
                                                            </>
                                                        }
                                                        {pc_equipment.headphone !== '' &&
                                                            <>
                                                                <input type="checkbox" id="headphone" name="headphone" value="Headphone" checked={pc_equipment.headphone} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="headphone">Headphone</label>
                                                            </>
                                                        }
                                                        {pc_equipment.mouse !== '' &&
                                                            <>
                                                                <input type="checkbox" id="mouse" name="mouse" value="mouse" checked={pc_equipment.mouse} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="mouse">Mouse</label>
                                                            </>
                                                        }
                                                        {pc_equipment.monitor !== '' &&
                                                            <>
                                                                <input type="checkbox" id="monitor" name="monitor" value="monitor" checked={pc_equipment.monitor} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="monitor">Monitor</label>
                                                            </>
                                                        }
                                                        {pc_equipment.laptop !== '' &&
                                                            <>
                                                                <input type="checkbox" id="laptop" name="laptop" value="Mouse" checked={pc_equipment.laptop} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="laptop">Laptop</label>
                                                            </>
                                                        }
                                                        {pc_equipment.keyboard !== '' &&
                                                            <>
                                                                <input type="checkbox" id="keyboard" name="keyboard" value="Keyboard" checked={pc_equipment.keyboard} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="keyboard">Keyboard</label>
                                                            </>
                                                        }
                                                    </span>
                                                </li>
                                            )}
                                            {(softwareAccessTest && (softwareAccess.office_365 !== '' || softwareAccess.dropbox !== '' || softwareAccess.autocad_aec !== '' || softwareAccess.autocad_lt !== '' || softwareAccess.adobe_creative_suite !== '' || softwareAccess.vray !== '' || softwareAccess.bluebeam !== '' || softwareAccess.atlassian_cloud !== '' || softwareAccess.hubspot !== '' || softwareAccess.google_cloud !== '' || softwareAccess.roboform !== '' || softwareAccess.blitz !== '' || softwareAccess.zoho !== '')) && (
                                                <li>
                                                    <strong>Software Access<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        {softwareAccess.office_365 !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Office" name="office_365" value="Office 365" checked={softwareAccess.office_365} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Office">Office 365</label>
                                                            </>
                                                        }

                                                        {softwareAccess.dropbox !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Dropbox" name="dropbox" value="Dropbox" checked={softwareAccess.dropbox} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Dropbox">Dropbox</label>
                                                            </>
                                                        }

                                                        {softwareAccess.autocad_aec !== '' && (
                                                            <>
                                                                <input type="checkbox" id="AutoCADAEC" name="autocad_aec" checked={softwareAccess.autocad_aec} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="AutoCADAEC">AutoCAD AEC</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.autocad_lt !== '' && (
                                                            <>
                                                                <input type="checkbox" id="AutoCADLT" name="autocad_lt" checked={softwareAccess.autocad_lt} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="AutoCADLT">AutoCAD LT</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.adobe_creative_suite !== '' && (
                                                            <>
                                                                <input type="checkbox" id="AdobeCreativeSuite" name="adobe_creative_suite" checked={softwareAccess.adobe_creative_suite} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="AdobeCreativeSuite">Adobe Creative Suite</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.vray !== '' && (
                                                            <>
                                                                <input type="checkbox" id="Vray" name="vray" checked={softwareAccess.vray} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Vray">V-Ray</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.bluebeam !== '' && (
                                                            <>
                                                                <input type="checkbox" id="Bluebeam" name="bluebeam" checked={softwareAccess.bluebeam} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Bluebeam">Bluebeam</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.atlassian_cloud !== '' && (
                                                            <>
                                                                <input type="checkbox" id="AtlassianCloud" name="atlassian_cloud" checked={softwareAccess.atlassian_cloud} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="AtlassianCloud">Atlassian Cloud</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.hubspot !== '' && (
                                                            <>
                                                                <input type="checkbox" id="Hubspot" name="hubspot" checked={softwareAccess.hubspot} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Hubspot">HubSpot</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.google_cloud !== '' && (
                                                            <>
                                                                <input type="checkbox" id="GoogleCloud" name="google_cloud" checked={softwareAccess.google_cloud} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="GoogleCloud">Google Cloud</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.roboform !== '' && (
                                                            <>
                                                                <input type="checkbox" id="roboform" name="roboform" checked={softwareAccess.roboform} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="roboform">RoboForm</label>
                                                            </>
                                                        )}
                                                        {softwareAccess.blitz !== '' && (
                                                            <>
                                                                <input type="checkbox" id="Blitz" name="blitz" checked={softwareAccess.blitz} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Blitz">Blitz</label>
                                                            </>
                                                        )}

                                                        {softwareAccess.zoho !== '' && (
                                                            <>
                                                                <input type="checkbox" id="Zoho" name="zoho" checked={softwareAccess.zoho} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Zoho">Zoho</label>
                                                            </>
                                                        )}
                                                    </span>
                                                </li>


                                            )}

                                            {(mobileAccessTest && (mobileAccess.teams !== '' || mobileAccess.outlook !== '' || mobileAccess.jira_software !== '')) && (
                                                <li>
                                                    <strong>Mobile Access<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        {mobileAccess.teams !== '' &&
                                                            <>
                                                                <input type="checkbox" id="MS_Teams" name="teams" value="MS Teams" checked={mobileAccess.teams} onChange={handlemobileCheckboxChange} />
                                                                <label htmlFor="MS_Teams">MS Teams</label>
                                                            </>
                                                        }
                                                        {mobileAccess.outlook !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Outlook" name="outlook" value="Outlook" checked={mobileAccess.outlook} onChange={handlemobileCheckboxChange} />
                                                                <label htmlFor="Outlook">Outlook</label>
                                                            </>
                                                        }
                                                        {mobileAccess.jira_software !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Jira_Mobile" name="jira_software" value="Jira" checked={mobileAccess.jira_software} onChange={handlemobileCheckboxChange} />
                                                                <label htmlFor="Jira_Mobile">Jira</label>
                                                            </>
                                                        }
                                                    </span>
                                                </li>
                                            )}



                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>
                                            {softwareArray.length > 0 && (
                                                <>
                                                    <li>
                                                        <strong>
                                                            Special Software Needs <i className={styles.red}>*</i>:
                                                        </strong>
                                                        <span>
                                                            <input
                                                                type="checkbox"
                                                                id="special_software_need"
                                                                name="special_software_needs"
                                                                onChange={handleCheckboxChange}
                                                                checked={formData.special_software_needs}
                                                            />
                                                            <label htmlFor="special_software_need"></label>
                                                        </span>
                                                    </li>

                                                   <li>
                                                    <strong style={{ display: 'inline-block', verticalAlign: 'top', width: '150px', fontWeight: 600 }}>
                                                        Special Software Name:
                                                    </strong>
                                                    <ul style={{ display: 'inline-block', verticalAlign: 'top', width: 'calc(100% - 160px)' }}>


                                                        <span style={{ paddingLeft: '10px', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>

                                                            {softwareArray.join(', ')}
                                                        </span>
                                                    </ul>
                                                    </li>
                                                </>
                                            )}
                                            {accessArray.length > 0 && (
                                                <li>
                                                    <strong>Additional Access<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            id="additional_access"
                                                            name="additional_access"
                                                            onChange={handleCheckboxChange}
                                                            checked={formData.additional_access}
                                                        />
                                                        <label htmlFor="additional_access"></label>
                                                    </span>

                                                    <strong style={{ display: 'inline-block', verticalAlign: 'top', width: '150px', fontWeight: 600 }}>
                                                        Additional Access Name:
                                                    </strong>
                                                    <ul style={{ display: 'inline-block', verticalAlign: 'top', width: 'calc(100% - 160px)' }}>


                                                        <span style={{ paddingLeft: '10px', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>

                                                            {accessArray.join(', ')}
                                                        </span>


                                                    </ul>
                                                </li>
                                            )}



                                            {formData.country !== '' && (
                                                <li>
                                                    <strong>Network Calling<i className={styles.red}>*</i>:</strong>
                                                    <span className={styles.calling}>

                                                        <input
                                                            type="checkbox"
                                                            id="network_calling"
                                                            name="network_calling"
                                                            checked={formData.network_calling}
                                                            onChange={handleCheckboxChange}
                                                        />

                                                        {formData.country !== '' && <label htmlFor="network_calling">Toggle</label>}
                                                    </span>
                                                </li>
                                            )}
                                            {formData.country !== undefined && formData.country !== '' && (
                                                <li>
                                                    <strong>Which Country <i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        {formData.country}
                                                    </span>
                                                </li>
                                            )}
                                            {formData.business_card_required !== '' && (
                                                <li><strong>Business Card Required<i className={styles.red}>*</i>:</strong> <span>

                                                    <input
                                                        type="checkbox"
                                                        id="business_card_required"
                                                        name="business_card_required"
                                                        checked={formData.business_card_required}
                                                        onChange={handleCheckboxChange}
                                                    />

                                                    {formData.business_card_required !== '' && <label htmlFor="business_card_required"></label>}
                                                </span></li>
                                            )}


                                            {formData.laptop_ready_for_shipment_show && (
                                                <li>
                                                    <strong>Laptop is ready and handed over to admin<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            id="laptop_ready_for_shipment"
                                                            name="laptop_ready_for_shipment"
                                                            checked={formData.laptop_ready_for_shipment}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="laptop_ready_for_shipment"></label>
                                                    </span>
                                                </li>
                                            )}
                                            <li><strong>Security Code:</strong> <span>
                                                <input type="checkbox" id="security_code" name="security_code" value="Yes" checked={formData.security_code}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="security_code"></label>

                                            </span></li>
                                            <li><strong>Security Information Sent:</strong> <span>
                                                <input type="checkbox" id="security_information_sent" name="security_information_sent" value="Yes" checked={formData.security_information_sent}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="security_information_sent"></label>

                                            </span></li>

                                        </ul>
                                    </div>
                                </div>
                                {formData.classification_comment &&(
                                     <div className={styles['right-space']}>
                                     <ul className={styles['two-col']}>
                                         <li><strong>Classification Comment:</strong> <span><textarea name="classification_comment" className={styles.input} value={formData.classification_comment} onChange={handleChange} ></textarea></span></li>
 
                                     </ul>
                                 </div>
                                )}
                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange} ></textarea></span></li>

                                    </ul>
                                </div>
                                {/* Button block */}
                                <div className={styles['button-block']}>
                                    {department === 'Human Resources' && !buttonsDisabled && (
                                        <input
                                            type="submit"
                                            value="Save"
                                            className={styles.button}
                                            onClick={hanldeSaveInfo}
                                            disabled={loading}
                                        />
                                    )}


                                    {!buttonsDisabled && (
                                        <input
                                            type="submit"
                                            value={buttonsDisabled ? 'Submitted' : 'Submit'}
                                            className={styles.button}
                                            onClick={hanldeSubmit}
                                            disabled={loading}
                                        />
                                    )}
                                    {department !== 'Human Resources' && !buttonsDisabled && (
                                        <input type="submit" value={buttonsDisabled ? 'Rejected' : 'Reject'} className={styles.button} onClick={handleReject} />
                                    )}
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-IT']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-IT']} `}>
                        <div className={styles.newsloader}></div></div>
                </div>}
                {isPopupOpen && (
                    <div className={styles.rejectpopup}>
                        <div className={styles.rejectpopupContent}>
                            <h6><strong>Are you sure you want to reject?</strong></h6>
                            <ul className={styles['two-col']} style={{ padding: '20px' }}>
                                <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="rejectComment" className={styles.input} onChange={handleCommentChange} style={{ width: 'calc(100% - 141px)' }}></textarea></span></li>

                            </ul>
                            <button className={styles.rejectcancelbutton} onClick={closePopup}>Cancel</button>
                            <button className={styles.rejectbutton} onClick={() => {
                                rejectPopup();
                            }}>
                                Confirm
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default IT;
